import React from 'react'
import { Wrapper, Layout } from 'compositions/Application'
import Routes from '@tools/routes'
import { theme } from '@tools/theme'
import NavBar from '@tools/compositions/NavBar'
import NavDrawer from '@tools/compositions/NavDrawer'
import { ContextProvider } from './Context'

export const Application: React.FC = () =>
  <Wrapper theme={theme}>
    <ContextProvider>
      <Layout navBar={NavBar} navDrawer={NavDrawer}>
        <Routes />
      </Layout>
    </ContextProvider>
  </Wrapper>

export default Application
