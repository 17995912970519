import React from 'react'
import { useLocation } from 'react-router'
import { useMe } from 'hooks'
import { Link as RouterLink } from 'react-router-dom'
import {
  NavDrawer as AppNavDrawer,
  NavDrawerProps,
} from 'compositions/NavDrawer'
import { MenuLink } from 'components'
import { ManageAccount as ManageAccountIcon } from 'icons'
import { MenuItem } from '@mui/material'

export const AccountMenuItems: React.FC<{ me: ReturnType<typeof useMe>, onClick?: React.MouseEventHandler }> = ({ onClick }) => {
  const location = useLocation()

  return <>
    <MenuItem>
      <MenuLink component={RouterLink} to="/profile" color={location.pathname === "/profile" ? "primary" : "text.primary"} onClick={onClick} onTouchEnd={onClick}>
        <ManageAccountIcon sx={{ marginRight: 1 }} />
        My Profile
      </MenuLink>
    </MenuItem>
  </>
}

export const NavDrawer: React.FC<Omit<NavDrawerProps, "AccountMenuItems" | "NavigationMenuItems">> = (props) =>{
  return <AppNavDrawer {...props} AccountMenuItems={AccountMenuItems} />
}

export default NavDrawer