import React from 'react'
import { Card, CardHeader, CardContent, Link } from 'components'
import type { Property } from 'csstype'
import type { ResponsiveStyleValue } from '@mui/system'
import type { Theme } from '@mui/material/styles'

export type AppCardProps = {
  loginUrl: string
  title: string
  description: string
  icon: React.ReactElement
  color: ResponsiveStyleValue<string[] | Property.Color> | ((theme: Theme) => ResponsiveStyleValue<string[] | Property.Color>)
}

export const AppCard: React.FC<AppCardProps> = ({ loginUrl, title, description, icon, color }) => {
  return <Card sx={{ flexGrow: 1, flexBasis: { xs: '100%', md: '40%', lg: '30%' } }}>
    <CardHeader
      title={<>
        <Link href={loginUrl} sx={{ color: 'inherit', display: 'inline-block', width: '100%', pt: 3 }}>{ icon }</Link>
        <Link href={loginUrl} sx={{ color: 'inherit', fontWeight: 'normal', display: 'inline-block', width: '100%', pb: 3 }}>{ title }</Link>
      </>}
      titleTypographyProps={{
        color: color,
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
      sx={{ py: 0 }}
    />
    <CardContent sx={{ textAlign: 'center', py: 0, ':last-child': { py: 0 } }}>
      <Link href={loginUrl} sx={{ color: 'inherit', fontWeight: 'normal', display: 'inline-block', width: '100%', py: 3, my: 0 }}>{ description }</Link>
    </CardContent>
  </Card>
}

export default AppCard
