import React from 'react'
import { useLoginUrl, useMe } from 'hooks'
import {
  Market as MarketIcon,
  Campus as CampusIcon,
  Kitchen as KitchenIcon,
  Kiosk as KioskIcon,
  Admin as AdminIcon,
} from 'icons'
import { Box, Page } from 'components'
import { AppCard } from './AppCard'

export const Home: React.FC = () => {
  const { hasMarketAccess, hasKioskAccess, hasKitchenAccess, hasCampusAccess, hasLuxoAccess } = useMe()
  const marketLoginUrl = useLoginUrl('market')
  const campusLoginUrl = useLoginUrl('campus')
  const kitchenLoginUrl = useLoginUrl('kitchen')
  const kioskLoginUrl = useLoginUrl('kiosk')
  const luxoLoginUrl = useLoginUrl('luxo')

  return <Page autoHeight>
    <Box display="flex" justifyContent="space-around" flexWrap="wrap" gap={2}>
      { hasKioskAccess && <AppCard
        loginUrl={kioskLoginUrl}
        icon={<KioskIcon sx={{ marginRight: 1 }} />}
        color={(theme) => theme.palette.kiosk.main}
        title="Cafeteria Kiosk"
        description="View student orders, accept deliveries and handout food"
      /> }

      { hasKitchenAccess && <AppCard
        loginUrl={kitchenLoginUrl}
        icon={<KitchenIcon sx={{ marginRight: 1 }} />}
        color={(theme) => theme.palette.kitchen.main}
        title="Kitchen Manager"
        description="Review upcoming orders, menus, dishes and more"
      /> }

      { hasCampusAccess && <AppCard
        loginUrl={campusLoginUrl}
        icon={<CampusIcon sx={{ marginRight: 1 }} />}
        color={(theme) => theme.palette.campus.main}
        title="School Admin"
        description="Manage your lunch program, documents and more"
      /> }

      { hasMarketAccess && <AppCard
        loginUrl={marketLoginUrl}
        icon={<MarketIcon sx={{ marginRight: 1 }} />}
        color={(theme) => theme.palette.plain.main}
        title="Order Meals"
        description="Place orders and manage your account"
      /> }

      { hasLuxoAccess && <AppCard
        loginUrl={luxoLoginUrl}
        icon={<AdminIcon sx={{ marginRight: 1 }} />}
        color={(theme) => theme.palette.luxo.main}
        title="Ordo Admin"
        description="Internal tools for Ordo team members only"
      /> }
    </Box>
  </Page>
}

export default Home
